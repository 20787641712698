import {React, useState, useEffect} from 'react';
import {useGA} from '../hooks';
import RegistrationForm from './RegistrationForm';
import AppAnnouncement from './AppAnnouncement';
import {Modal} from '@ct/assemblage';
import {useLightbox} from './LightboxContext';

function LightboxModal() {
  const {
    isMobile,
    isNative, 
    registrationLocalStorageVar, 
    appAnnouncementLocalStorageVar, 
    variationIndex, 
    showRegistration, 
    showAppAnnouncement, 
    campaign
  } = useLightbox();
  const {track} = useGA();
  const [isOpen, setIsOpen] = useState(false);
  const redirect = document?.location?.href;

  const OPEN_DELAY = 5000; // 5 seconds

  useEffect(() => {
    const delayOpen = setTimeout(() => {
      // only open lightbox if at least one of the conditions is met
      if (showRegistration || showAppAnnouncement) {
        openLightbox();
      }

    }, OPEN_DELAY);

    return () => clearTimeout(delayOpen);
  }, []);

  // taken from ctmobile.js and ctcore.js
  // can be moved to util library later
  // but right now only used here so putting it here
  const getMixpanelPlatform = (isMobile) => {
		// taken from StackOVerflow: https://stackoverflow.com/a/901144
		// needed to ensure compatibility with older browsers
		// URLSearchParams is not available in IE and older browsers
		function getParameterByName(name, url = window.location.href) {
			name = name.replace(/[\[\]]/g, '\\$&');
			let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i'),
				results = regex.exec(url);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, ' '));
		}

		let mixpanelQueryParam = getParameterByName("mp");
		let mixpanelPlatform;

		 if (isMobile) {
      // mixpanel query param takes precedence
      if(mixpanelQueryParam) {
        switch(mixpanelQueryParam) {
          case "ima":
            mixpanelPlatform = "In-Market App";
            break;
          case "rna":
            mixpanelPlatform = "React Native App";
            break;
          default:
            mixpanelPlatform = "Unknown";
        }
      } else { // check meta info on web
        let metaPlatform = document.querySelector('meta[name="platform"]').content;
        switch(metaPlatform) {
          case "InMarketApp":
            mixpanelPlatform = "In-Market App";
            break;
          case "MobileWeb":
            mixpanelPlatform = "Mobile Web";
            break;
          case "DesktopWeb":
            mixpanelPlatform = "Desktop Web";
            break;
          default:
            mixpanelPlatform = "Unknown";
        }
      }
		} else { // Desktop Web, ima and rna values come from external link
      switch(mixpanelQueryParam) {
				case "ima":
					mixpanelPlatform = "In-Market App";
					break;
				case "rna":
					mixpanelPlatform = "React Native App";
					break;
				default:
					mixpanelPlatform = "Desktop Web";
			}
    }

		return mixpanelPlatform;
	}

  const openLightbox = () => {
    setIsOpen(true);
    
    if(showRegistration) {
      localStorage.setItem(registrationLocalStorageVar, Date.now().toString());
    } else {
      localStorage.setItem(appAnnouncementLocalStorageVar, Date.now().toString());
    }

    track({name: 'lightbox_view', action: 'View', version: campaign});
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const requestClose = (event, reason) => {
    const action = reason === 'closeClick' ? 'Close' : 'Dismiss';
    const name = `lightbox_${action.toLowerCase()}`;
    track({name, action, version: campaign});
    closeLightbox();
  };

  const onRegisterSuccess = () => {
    track({
      name: 'auth_complete_sign_up',
      action: 'Registration',
      version: campaign,
    });

    // we initiate mixpanel in ASP and mobile web app
    // and only track this event for unregistered users
    // see trackadduser in ctcore.js
    if (window.mixpanel) {
      if (window.mixpanel.has_opted_out_tracking()) {
        window.mixpanel.opt_in_tracking();
      }
      window.mixpanel.track(
        'complete account registration',
        {
          "action": `complete`,
          "entity": `account registration`,
          "category": `authentication`,
          "variant": `lightbox`,
          "platform": getMixpanelPlatform(isMobile),
          "screen_name": window.location.pathname.replace(/[0-9]/g, ``) || `unknown`, // parse out the numbers from the URL for easier aggregation in mixpanel
        }
      );
    }
  };


  // Registration lightbox has precedence over app announcement
  return (
    <Modal 
      open={isOpen} 
      onClose={requestClose}
      layout={isMobile? "fullscreen" : "center"}
    >
      {showRegistration ?
        <RegistrationForm
          variationIndex={variationIndex}
          redirect={redirect}
          handleRegisterSuccess={onRegisterSuccess}
          handleClose={closeLightbox}
        />
      :
        <AppAnnouncement isMobile={isMobile} isNative={isNative}/>}
    </Modal>
  );
}

export default LightboxModal;